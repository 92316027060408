
import { defineComponent } from 'vue';
import { emitter } from '../main';
export default defineComponent({
	data():any {
		return {
			isSidebarToggle: true,
			year:''
		};
	},
	mounted() {
		const d = new Date();
		this.year = d.getFullYear();
		emitter.on('toggle-sidebar', (isToggle: any) => {
			this.isSidebarToggle = isToggle;
		});
	}
});
