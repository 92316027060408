
import { defineComponent } from 'vue';
import { toast } from '../main';
import spinner from "@/components/spinner.vue"
export default defineComponent({
	data(): any {
		return {
			allTenants: [],
			apps: [],
			isLoader:false,
			activateTenants: [],
			currentSort: 'name',
			currentSortDir: 'asc',
			pageSize: 12,
			currentPage: 1
		};
	},

	components:{
		spinner
	},
	computed: {
		sortedData: function ():any {
			return [...this.allTenants]
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		cantGoBack():any {
			return this.currentPage === 1;
		},
		cantGoForward() :any{
			return this.teamsList.length / this.pageSize <= this.currentPage;
		}
	},

	methods: {
			filteredFunction(filterColumn: any, columnName: any) {
			this.teamsList = this.dummyList.filter((item: any) => {
				for (var property in item) {
					if (property == columnName) {
						return item[property].toLowerCase().includes(filterColumn.toLowerCase());
					}
				}
			});
		},
		nextPage: function () {
			if (this.currentPage * this.pageSize < this.teamsList.length) this.currentPage++;
		},
		prevPage: function () {
			if (this.currentPage > 1) this.currentPage--;
		},
		getAllTenants() {
			this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				this.allTenants = res.data;
				this.apps = this.allTenants[0].apps;
			});
		},
		oneditClient(tenant: any): any {
			this.$router.push({ name: 'edit-organisation', params: { organisationId: tenant._id } });
		},
		addClient(){
			this.$router.push({ name: 'add-organisation' })
		},

		onActivateTenant(tenant: any) {
			this.isLoader = true
			this.$http
			.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/` + tenant._id + `/${tenant.isActive ? 'activate' : 'inactivate'}`)
			.then(() => {
				this.isLoader = false
				toast.success(`${tenant.isActive ? 'Succesfully Activated' : 'Succesfully In-Activated'}`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				
			})
			.catch(() => {
				this.isLoader = false
				toast.error(` Not Activated`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			});
		},
		onUpdateApp(tenant: any, app: any) {
			this.isLoader = true
			this.$http
				.post(`${process.env.VUE_APP_AUTH_API_URL}/tenant/` + tenant._id + `/app/update`, app)
				.then(() => {
					this.isLoader =false
					toast.success(`Successfully Updated.`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					
				})
				.catch(() => {
					this.isLoader =false
					toast.error(` Not Updated`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					
				});
		}
	},
	mounted() {
		this.getAllTenants();
	}
});
