import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import defaultLayout from "@/layouts/defaultLayout.vue";
import onboarding from "@/views/onboarding.vue"
import organisations from "@/views/organisations.vue";
import organisationUserActivity from "@/views/organisationUserActivity.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/organisation/add",
        name: "add-organisation",
        meta: {
            title: "Join Us",
            layout: defaultLayout,
        },
        component: onboarding,
    },
    {
        path: "/organisation/:organisationId?/edit",
        name: "edit-organisation",
        meta: {
            title: "Join Us",
            layout: defaultLayout,
        },
        component: onboarding,
    },
    {
        path: "/organisations",
        name: "organisations",
        meta: {
            title: "Clients Information",
            layout: defaultLayout,
        },
        component: organisations,
    },
    {
        path: "/admin",
        name: "admin",
        redirect:'/organisations'
    },
    {
        path: "/organisationsactivity",
        name: "organisationsactivity",
        meta: {
            title: "Organisation User Activity",
            layout: defaultLayout,
        },
        component: organisationUserActivity,
    },
    {
        path: "/",
        name: "home",
        redirect:'/organisations'
    },
    {
        path: '/error',
        name: 'error',
        meta: {
            title: 'error',
            layout: 'empty-layout',
        },
        props:true,
        component: () => import('@/components/error.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/error',
    },

]
const router = createRouter({
    history: createWebHistory('/'),
    routes,
});


router.beforeEach(async (to, from, next) => {
    document.title=`Admin- ${to.meta.title}`
    console.log('Router check triggered', to)
    store.state.isLoading = true;
    // next()
    // if (to.path == '/org/dashboard' || to.path == '/admin' || to.path == '/admin/courses/getall' || to.path == '/') {
    //     console.log('Nav to default route with', to.query.session)
        if (to.query.session) {
            localStorage.setItem('AUTH_ID', to.query.session.toString());
            await store.dispatch('getUserInfo').then((res) => {
                console.log('response', res.data[0]);
                if (!res.data[0]) window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ADMIN_WEBAPP_URL}`;
                else {
                    next();
                }
            })
        }
        normalAuthenticationCheck(next);
    // } else {
    //     normalAuthenticationCheck(next);
    // }
});
router.afterEach(async (to, from, next) => {
    store.state.isLoading = false
});

async function normalAuthenticationCheck(next: any) {
    console.log('Inside normal check')
    if (!localStorage.getItem('AUTH_ID')) {
        console.log('AUTH ID NOT found')
        window.location.href = `${process.env.VUE_APP_AUTH_WEBAPP_URL}/session?returnTo=${process.env.VUE_APP_ADMIN_WEBAPP_URL}`;
    } else {
        const userInfo = store.getters.userInfo;
        console.log('USER INFO', userInfo)
        if (!userInfo.roles) {
            await store.dispatch('getUserInfo').then((res) => {
                next();
            });
        }
        else {
            next();
        }
    }
}



export default router;