
import { defineComponent } from 'vue';
import { emitter,toast } from '../main';
import { mapGetters } from 'vuex';
export default defineComponent({
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		getlocation() {
			return window.location.origin;
		}
	},
	data():any {
		return {
			isToggle: true,
			isappsToggle:false,
			showBlock: false,
			displayName:'',
		};
	},
	methods: {
		closeApps(){
			this.isappsToggle=false;
			emitter.emit('apps-sidemenubar',this.isappsToggle);
		},
		ontoggle() {
			this.isappsToggle = !this.isappsToggle;
			emitter.emit('apps-sidemenubar', this.isappsToggle);
		},

		getUserName(){
			return this.userInfo.name.charAt(0).toUpperCase()
		},
		
		 async logout() {
			var payload = {headers: {
            Authorization: 'Bearer ' + localStorage.getItem('AUTH_ID')
          }}
		 await  this.$http
					.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_API_PATH}/logout`, payload)
					.then((response: any) => {
						if (response.status == 200) {
						toast.error(`Logout Successfully`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						}
					})
					.catch((error: any) => {
						toast.error(`Save failed with ${error}`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
			
			localStorage.removeItem('AUTH_ID');
			window.location.replace(`${process.env.VUE_APP_AUTH_WEBAPP_URL}/logout?returnTo=${process.env.VUE_APP_ADMIN_WEBAPP_URL}`);
		},
		async getAllTenants() {
			this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_API_PATH}/tenants/getAll`).then((res: any) => {
				this.allTenants = res.data;
				console.log("aaa",this.allTenants)
			this.allTenants.filter((tenant:any) => {
			let userDomain = this.userInfo.email.substring(this.userInfo.email.lastIndexOf("@") + 1);
            let  userOrgDetails = userDomain.split('.')[0];
			if(this.userInfo.organisationType==10701){
				this.displayName=this.userInfo.organizationName
			}
			if( userOrgDetails.toLowerCase()==tenant.tenantName.toLowerCase()){
				this.displayName= tenant.displayName
			}
					
				});
			});
		}
	},

	mounted() {
		this.getAllTenants();
		emitter.on("apps",(isToggle:any)=>{
			this.isappsToggle=isToggle;
		});
	}
});
