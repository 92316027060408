
import { required,email, helpers } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import useVuelidate from '@vuelidate/core';
const numeric = helpers.regex( /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);

import { defineComponent } from 'vue';
import { toast } from '../main';
export default defineComponent({
	name: 'app',
	data() :any{
		return {
            v$: useVuelidate(),
			onboarding: {
				organisationName: '',
				address: '',
				phoneNumber: '',
				website: '',
				domain: '',
				contactPerson: '',
				contactPersonEmail: '',
				subDomain: '',
				displayName:'',
			},
			isLoading: false,
			pageType:'',
			orgActive:false,
			allApps:[]
		};
	},
	validations() {

        return{
	onboarding: {
			organisationName: { required },
			address: { required },
			contactPerson: { required },
			contactPersonEmail: { required, email },
			phoneNumber: { required ,numeric},
			website: { required },
			displayName:{required}
		}
        }
		
	},
	computed: {
		...mapGetters({
			userInfo: 'userInfo'
		})
	},
	methods: {
		async addOrganization(payload: any) {
			await this.$http
				.post(`${process.env.VUE_APP_AUTH_API_URL}/organization/add`, payload)
				.then((res:any) => {
					console.log("response",res.data.errorStatus)
					if(res.data.errorStatus){
							toast.error(res.data.errorStatusData, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.$router.back();
					}
					if (res.status == 200) {
						toast.success(`Organisations Added.`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.$router.back();
					}
				})
				.catch((err:any) => {
					toast.error(err, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		async updateOrganization(payload: any) {
			await this.$http
				.post(`${process.env.VUE_APP_AUTH_API_URL}/organization/${this.$route.params.organisationId}/update`, payload)
				.then((res:any) => {
					console.log("response",res.data.errorStatus)
					if(res.data.errorStatus){
							toast.error(res.data.errorStatusData, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.$router.back();
					}
					if (res.status == 200) {
						toast.success(`Organisations Updated.`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.$router.back();
					}
				})
				.catch((err:any) => {
					toast.error(err, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		onSubmit() {
            console.log("save")
			this.submitted = true;
			this.v$.$touch();
			if (this.v$.$invalid) {
                 console.log("savedsdd")
				return;
			}
			let fullDomain = this.onboarding.contactPersonEmail.substring(this.onboarding.contactPersonEmail.lastIndexOf("@") + 1);
            let orgName = fullDomain.split('.')[0];
			let urldomain=this.onboarding.website.startsWith("http://") ? 'http://' : 'https://'
			let website=this.onboarding.website.replace(urldomain, '');
			if(this.onboarding.organisationLogo=='' || this.onboarding.organisationLogo==undefined ||this.onboarding.organisationLogo.includes('clearbit')){
				this.onboarding.organisationLogo=`https://logo.clearbit.com/${website}`
			}

			let payload:any = {
				organizationName: this.onboarding.organisationName,
				orgLogo: '',
				address: this.onboarding.address,
				contactNumber: this.onboarding.phoneNumber,
				websiteUrl: this.onboarding.website,
				contactPerson: this.onboarding.contactPerson,
				tenantName: orgName,
				displayName:this.onboarding.displayName,
				organizationLogo: this.onboarding.organisationLogo,
				contactPersonEmail:this.onboarding.contactPersonEmail,
				
			};

			if(this.pageType=='add'){
				payload.isActive=false
				payload.apps=[{appId:10001,appName:'Monitor',isActive:false},{appId:10002,appName:'Training',isActive:false}],
				payload.createdBy= this.userInfo._id,
				payload.createdAt= new Date(),
				this.addOrganization(payload);
			}
			else{
				payload.isActive=this.orgActive
				payload.apps=this.allApps
				payload.updatedBy= this.userInfo._id,
				payload.updatedAt= new Date(),
				this.updateOrganization(payload)
			}
			// console.log('payload',payload);
			
		},
		async getSingleOrganization() {
			this.spinnerLoading = true;
			await this.$http
				.get(`${process.env.VUE_APP_AUTH_API_URL}/organization/${this.$route.params.organisationId}/get`)
				.then((res: any) => {
					this.spinnerLoading = false;
					this.onboarding = res.data[0];
					let orgName
					this.onboarding.organisationName = this.onboarding.organizationName
					this.onboarding.phoneNumber = this.onboarding.contactNumber
					this.onboarding.website =this.onboarding.websiteUrl
					this.onboarding.organisationLogo = this.onboarding.organizationLogo
					this.allApps=this.onboarding.apps
					this.orgActive= this.onboarding.isActive

					
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
	},
	mounted() {
		this.pageType = this.$route.fullPath.includes('add') ? 'add' : 'edit';
		console.log(this.$route)
		if (this.$route.params.organisationId) {
			console.log("inside")
			this.getSingleOrganization()
		}
	}
});
